import { merge } from 'lodash';
import EditorData from 'components/editor-data/EditorData';
import Resources from 'components/data/Resources';
import DataHelpers from 'components/data/DataHelpers';
import Loop from './loop';
import Translate from './translate';

/**
 * HTML5GIF
 * This is a special publish profile parser for HTML5 setup.
 */
export default class HTML5GIF {
    static async handle(task) {
        const allFormats = Resources.get('displayFormats');

        const data = EditorData.getValueFromModel(task.sourceDataModel);
        const market = EditorData.get('market');
        const newTasks = [];
        const allFiles = [];
        const outputGifs = {};
        const outputCombinations = Loop.getCombinations(task.multiLanguage, task.loopItems);

        if (task.formatsModel) {
            task.formats = EditorData.getValueFromModel(task.formatsModel);
        }

        outputCombinations.forEach((variables) => {
            let filenameString = '';

            Object.keys(variables).forEach((key) => {
                const item = variables[key];
                const filenamePart = item.toLowerCase().replace(/[^a-z0-9]/gi, '_');
                filenameString = filenameString + '-' + filenamePart;
            });

            // Loop through tasks
            task.formats.forEach((format) => {
                // Get width and height
                let width;
                let height;
                if (allFormats[format]) {
                    width = allFormats[format].width;
                    height = allFormats[format].height;
                } else if (format.substr(0, 6) == 'format') {
                    const formatKeyParts = format.split('_');
                    const sizes = formatKeyParts[1].split('x');
                    width = parseInt(sizes[0]);
                    height = parseInt(sizes[1]);
                }

                // Create new data
                let formatData = DataHelpers.clone(data);

                // Find the right data format in the overwrites en merge those
                if (formatData && formatData.overwrites && formatData.overwrites[format]) {
                    formatData = merge(formatData, formatData.overwrites[format]);
                }
                if (formatData && formatData.overwrites) {
                    delete formatData.overwrites;
                }

                if (variables.language) {
                    Translate.handle(variables.language, formatData);
                }

                // Merge other data that we want to include at publish time
                if (task.mergeData) {
                    // Get the data to be merged from the task
                    const mergeData = DataHelpers.clone(task.mergeData);
                    // Merge the objects together
                    formatData = merge(formatData, mergeData);
                    // In case there are variables
                    EditorData.parseDataDeep(formatData, variables);
                }

                const modelObject = {
                    ...formatData,
                    format: format,
                    frameNr: 0,
                    editmode: 0,
                    market: market
                };

                let baseTemplateUrl = task.imageTemplate;
                if (!baseTemplateUrl.endsWith('/') && !baseTemplateUrl.endsWith('index.html')) {
                    baseTemplateUrl = baseTemplateUrl + '/';
                }
                const encodedSrc = `${baseTemplateUrl}#${encodeURIComponent(JSON.stringify(modelObject))}`;
                const formatString = format + '-' + width + 'x' + height;

                // Build gifs
                newTasks.push({
                    service: 'creatives',
                    type: 'HTMLToGIF',
                    htmlUrl: encodedSrc,
                    width: width,
                    height: height,
                    name: format,
                    waitTime: task.waitTime,
                    frameTime: task.frameTime,
                    frames: task.frames,
                    resourceId: task.displayKey + '-' + formatString + filenameString + '-gif',
                    quality: task.quality ? task.quality : null, // optional
                    repeat: task.repeat ? task.repeat : null, // optional
                    screenshotTime: task.screenshotTime ? task.screenshotTime : null, // optional
                    startOnClick: task.startOnClick ? task.startOnClick : null // optional
                });

                // Create output folder
                outputGifs[task.displayKey + '-' + formatString + filenameString + '.gif'] =
                    '{{' + task.displayKey + '-' + formatString + filenameString + '-gif}}';

                // Add the files to the all files array of the function
                allFiles.push({
                    name: task.displayKey + '-' + formatString + filenameString + '.gif',
                    file: '{{' + task.displayKey + '-' + formatString + filenameString + '-gif}}'
                });
            });
        });

        // Merge tasks (creation of zip, packing it and publishing it)
        const files = {
            gifs: outputGifs
        };

        // Setup task list
        let taskResult = [];
        if (!task.disableParallel) {
            taskResult = [
                {
                    service: 'publish',
                    type: 'parallel',
                    tasks: newTasks
                }
            ];
        } else {
            taskResult = newTasks;
        }

        taskResult = [
            ...taskResult,
            {
                service: 'files',
                type: 'archive',
                files: files,
                resourceId: 'archive'
            },
            {
                service: 'publish',
                type: 'product',
                name: 'Download zip',
                product: '{{archive}}'
            }
        ];

        // Adds links file
        // This option includes a txt file with all urls.
        if (task.addLinksFile) {
            let outputText = '';
            allFiles.forEach((item) => {
                outputText = outputText + item.name + ': ' + item.file + '\n';
            });

            taskResult.push({
                service: 'files',
                type: 'storeFile',
                extension: 'txt',
                source: outputText,
                filename: 'all-links',
                resourceId: 'all-links'
            });
            taskResult.push({
                service: 'publish',
                type: 'product',
                name: 'Download all links',
                product: '{{all-links}}'
            });
        }

        return taskResult;
    }
}
