import EditorData from 'components/editor-data/EditorData';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';

/**
 * Export to Excel
 * This function loops through the entire campaign setup and converts it into a JSON that is converted to XLSX by the publishengine.
 */
export default class ExportToExcel {
    static async handle(task) {
        const editor = EditorData.get();
        const structure = editor.interfaceSetup;

        // Get languages
        const tabs = [];
        let languages = [''];
        languages = Object.keys(EditorData.getValueFromModel('settings.languages'));
        languages.forEach((language) => {
            // Loop through pages
            structure.pages.forEach((page) => {
                if (page.condition && !EditorData.validateCondition(page)) {
                    return;
                }

                let title = page.title;
                if (language != '') {
                    title = title + ' - ' + language;
                }

                const excelTab = {
                    title: title,
                    rows: []
                };

                // Loop through tabs or page
                if (page.tabs) {
                    page.tabs.forEach((tabItem) => {
                        if (tabItem.condition && !EditorData.validateCondition(tabItem)) {
                            return;
                        }
                        // Create tab title in Excel
                        excelTab.rows.push(['****' + tabItem.title + '****']);
                        excelTab.rows.push(['']);

                        // Go trough all blocks
                        ExportToExcel.handleBlocks(tabItem, excelTab, language);
                    });
                } else {
                    ExportToExcel.handleBlocks(page, excelTab, language);
                }

                tabs.push(excelTab);
            });
        });

        // Return new task
        const newTasks = [
            {
                service: 'files',
                type: 'JSONToXLSXFreeFormat',
                data: tabs,
                resourceId: 'excel'
            },
            {
                service: 'publish',
                type: 'product',
                name: 'Download Excel',
                product: '{{excel}}'
            }
        ];

        // Export images with it
        if (task.exportImages) {
            const zipTask = {
                service: 'files',
                type: 'archive',
                files: {
                    'export.xlsx': '{{excel}}',
                    images: {}
                },
                resourceId: 'ad_archive'
            };

            if (task.images) {
                for (const i in task.images) {
                    const data = task.images[i];

                    // We have subsets, walk through them
                    if (data.blockModelBase) {
                        const blockModelBaseData = EditorData.getValueFromModel(data.blockModelBase);
                        let index = 0;

                        // Loop through all blocks
                        for (const ib in blockModelBaseData) {
                            index++;
                            // Create new filename
                            let filename = data.filename;
                            const extension = filename.split('.').pop();
                            filename = filename.replace('.' + extension, '_' + index + '.' + extension);

                            // Add to zip
                            const model = data.model.replace('[[subset]]', ib);
                            zipTask.files.images[filename] = EditorData.getValueFromModel(model);
                        }
                    }
                    // Individual image
                    else {
                        zipTask.files.images[data.filename] = EditorData.getValueFromModel(data.model);
                    }
                }
            }

            // Add tasks
            newTasks.push(zipTask);
            newTasks.push({
                service: 'publish',
                type: 'product',
                name: 'Download ZIP',
                product: '{{ad_archive}}'
            });
        }

        return newTasks;
    }

    /**
     * Handle blocks
     * @param {*} tabItem The current tab with blocks that we want to use
     * @param {*} excelTab Current Excel tab. This is written to
     */
    static handleBlocks(tabItem, excelTab, language) {
        if (tabItem.blocks) {
            tabItem.blocks.forEach((block) => {
                let currentBlock = block;
                if (block.main) {
                    currentBlock = block.main;
                }

                // Check if condition is there
                if (!currentBlock.items || (currentBlock.condition && !EditorData.validateCondition(currentBlock))) {
                    return;
                }
                // Set title of the block
                const newRows = [];
                newRows.push(['***' + block.title + '***']);

                // Subset list
                // Create a list of subsets in case available.
                let subsetList = [''];
                if (block.subsets) {
                    const blockModelBase = EditorData.getValueFromModel(block.blockModelBase);
                    if (blockModelBase) {
                        subsetList = Object.keys(blockModelBase);
                    }
                }

                subsetList.forEach((subsetKey, subsetIndex) => {
                    if (subsetList.length > 1) {
                        newRows.push(['***-Variant ' + (subsetIndex + 1) + '***']);
                    }

                    currentBlock.items.forEach((item) => {
                        // Subsection
                        if (item.type == 'subSection') {
                            item.items.forEach((subItem) => {
                                const val = ExportToExcel.getValue(block, subItem, language, subsetKey);
                                if (val) {
                                    newRows.push([subItem.label, val]);
                                }
                            });
                        }
                        // Regular field
                        else {
                            const val = ExportToExcel.getValue(block, item, language, subsetKey);
                            if (val) {
                                newRows.push([item.label, val]);
                            }
                        }
                    });
                });

                // We have items, add some blank lines
                if (newRows.length > 1) {
                    newRows.push(['']);
                    newRows.push(['']);
                    excelTab.rows = [...excelTab.rows, ...newRows];
                }
            });
        }
    }

    /**
     * Get value from a field
     * @param {*} item
     */
    static getValue(currentBlock, item, language, subset = '') {
        let model = item.model;

        if (!item) {
            return;
        }

        if (currentBlock.blockModel) {
            model = currentBlock.blockModel + '.' + model;
        }

        if (!model) {
            return;
        }
        model = model.replace('[[subset]]', subset);
        let value = EditorData.getValueFromModel(model);

        if (EditorData.getValueFromModel(model + '.' + language + '.value')) {
            value = EditorData.getValueFromModel(model + '.' + language + '.value');
        } else if (EditorData.getValueFromModel(model + '.value')) {
            value = EditorData.getValueFromModel(model + '.value');
        } else if (EditorData.getValueFromModel(model + '.url')) {
            value = EditorData.getValueFromModel(model + '.url');
        }

        // Return the values
        if (Object.prototype.toString.call(value) === '[object String]') {
            return value;
        } else if (Array.isArray(value)) {
            return value.join(', ');
        }
        return false;
    }
}
