import moment from 'moment';
import EditorData from 'components/editor-data/EditorData';
import Templates from 'components/data/Templates';
import DataHelpers from 'components/data/DataHelpers';
import EmailHelpers from 'components/data/EmailHelpers';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import store from '../../../../store';

/**
 * Email task handler
 * Creates email htmls from the blocks
 */
export default class Email {
    /**
     * Handle email build
     * @param {*} task
     */
    static async handle(task) {
        const setup = EditorData.getValueFromModel(task.model);
        const abTesting = EditorData.getValueFromModel(task.abTesting);
        const dataModel = task.dataModel;
        const campaign = store.getState().editor.data;

        // Get the optional title to name the published products.
        const productNamePrefix = task.title ? `${task.title} - download` : 'Download';

        // Get the settings model if specified. Otherwise use default.
        let settingsModel = task.settingsModel;
        if (!settingsModel) settingsModel = 'email.settings';

        // Get the consditions model if specified. Otherwise use default.
        let conditionsModel = task.conditionsModel;
        if (!conditionsModel) conditionsModel = 'email.conditions';

        // Groups
        const groups = [];
        if (abTesting && abTesting !== 'none') {
            for (let i = 0; i < abTesting.length; i++) {
                groups.push(abTesting[i]);
            }
        } else {
            groups.push('all');
        }

        // Get base
        await Templates.asyncGetTemplatesList([{ type: 'emailBase', fullData: true }]);
        const bases = Templates.get('emailBase');

        let base = {};
        Object.keys(bases).forEach((key) => {
            const item = bases[key];
            if (!task.groupKey || item.groupKey === task.groupKey) {
                base = item;
            }
        });

        // Create arrays for languages
        let languages = [''];
        if (task.multiLanguage || task.multiLanguageInMail) {
            languages = Object.keys(EditorData.getValueFromModel('settings.languages'));
        }
        // Handle origins
        let origins = ['GEN'];
        if (task.multiOrigin) {
            const scenario = EditorData.getValueFromModel(settingsModel + '.scenario');
            if (!scenario || scenario !== 'multipleOrigins') {
                const originsFromModel = EditorData.getValueFromModel('settings.origins');
                origins = [];
                originsFromModel.forEach((item) => {
                    origins.push(item.id);
                });
            }
        }

        // Create all email htmls based on the variants
        const htmlBlocks = await Email.setupHTMLBlocks(languages, origins, groups, settingsModel, dataModel, setup, conditionsModel, task);

        let mailHTMLs;
        // We have a custom version for this customer
        const customerHelper = new CustomerHelperLoader.helper();
        if (customerHelper.publishEmailHTMLOverwrites) {
            mailHTMLs = await customerHelper.publishEmailHTMLOverwrites(task, languages, origins, groups, settingsModel, base, htmlBlocks, conditionsModel);
        }
        // We have the regular email html setup
        else {
            mailHTMLs = await Email.setupHTMLs(task, languages, origins, groups, settingsModel, base, htmlBlocks, conditionsModel);
        }

        // Create list of the new tasks
        const newTasks = await Email.getTaskList(task, mailHTMLs, campaign, productNamePrefix);

        return newTasks;
    }

    /**
     * Setup blocks
     * This goes through all the blocks of the email and adds them to an object
     * Later we'll merge them into one email file.
     */
    static async setupHTMLBlocks(languages, origins, groups, settingsModel, dataModel, setup, conditionsModel, task) {
        const htmlBlocks = {};
        await Templates.asyncGetTemplatesList([{ type: 'emailBlock', fullData: true }]);
        const templates = Templates.get('emailBlock');

        // Languages loop
        languages.forEach((language) => {
            origins.forEach((origin) => {
                groups.forEach((group) => {
                    // Get variables
                    let campaign = store.getState().editor.data; // eslint-disable-line
                    let editor = DataHelpers.clone(store.getState().editor); // eslint-disable-line
                    editor.origin = origin; // eslint-disable-line
                    let market = editor.market; // eslint-disable-line
                    let customerHelper = new CustomerHelperLoader.helper(); // eslint-disable-line
                    let testVariant = group ? group : ''; // eslint-disable-line

                    // Get subject
                    function getSubject() {
                        // eslint-disable-line
                        // eslint-disable-line
                        return getElementCopy('subject');
                    }

                    // Get snippet
                    function getSnippet() {
                        // eslint-disable-line
                        // eslint-disable-line
                        return getElementCopy('snippet');
                    }

                    // Get element with copy
                    function getElementCopy(path) {
                        // eslint-disable-line
                        let element = DataHelpers.getValue(campaign, settingsModel + '.' + path + '.' + language + '.value');
                        // Get subject from subset
                        const subsetActive = DataHelpers.getValue(campaign, settingsModel + '.generalSubset');
                        if (subsetActive) {
                            const subsetElement = DataHelpers.getValue(
                                campaign,
                                settingsModel + '.subsets.' + subsetActive + '.' + path + '.' + language + '.value'
                            );
                            // If there is no element in subset, use the original one.
                            element = subsetElement ? subsetElement : element;
                        }

                        if (!element) {
                            return '';
                        }

                        return element;
                    }

                    // Get element
                    function getElement(path) {
                        // eslint-disable-line
                        const element = DataHelpers.getValue(campaign, settingsModel + '.' + path + '.' + language + '.value');
                        return element;
                    }

                    // Get general copy
                    function getGeneralCopy(path) {
                        // eslint-disable-line
                        const value = DataHelpers.getValue(campaign, path + '.' + language + '.value');
                        return value;
                    }

                    if (!setup) {
                        throw new Error('The email was not defined yet.');
                    }

                    let blockNr = 1; // eslint-disable-line

                    // Loop through all blocks
                    setup.forEach((x) => {
                        const blockTemplate = templates[x.identifier];

                        // Get data (from subsets)
                        const subsetData = DataHelpers.getValue(campaign, dataModel + '.' + x.uuid + '.subsetData');
                        let subsetActive = DataHelpers.getValue(campaign, dataModel + '.' + x.uuid + '.subsetActive');
                        const blockTestGroups = DataHelpers.getValue(campaign, dataModel + '.' + x.uuid + '.abTestGroups');
                        let block = {};

                        if (!subsetActive) {
                            subsetActive = 'main';
                        }

                        block = DataHelpers.getValue(campaign, dataModel + '.' + x.uuid);
                        if (subsetData) {
                            block = DataHelpers.getValue(campaign, dataModel + '.' + x.uuid + '.subsetData.' + subsetActive);
                        }

                        // Get item
                        /* eslint-disable */
                        function get(path) {
                            path = path.replace(/\[language\]/g, '.' + language);
                            const value = DataHelpers.getValue(block, path, '');
                            return EmailHelpers.cleanup(value);
                        }

                        // Find the testgroups for this block
                        let abTestGroups = ['all'];
                        if (blockTestGroups && blockTestGroups.split) {
                            abTestGroups = blockTestGroups.split(',');
                        }

                        // Eval HTML
                        let html = blockTemplate.html;
                        let isEditor = false;
                        let testGroup = group;
                        try {
                            html = eval('`' + blockTemplate.html + '`');
                        } catch (e) {
                            console.log('Error rendering email block ', e);
                        }
                        /* eslint-enable */

                        if (customerHelper.publishEmailHTMLProcessingBlock) {
                            html = customerHelper.publishEmailHTMLProcessingBlock(html, task, x.identifier, blockNr);
                        }

                        // Validate the AB test group. In case we are exporing all, or the block is included in all, or this block should be included, include this block in the export.
                        if (group === 'all' || abTestGroups.includes('all') || abTestGroups.includes(group)) {
                            if (!htmlBlocks[language + '_' + origin + '_' + group]) {
                                htmlBlocks[language + '_' + origin + '_' + group] = [];
                            }
                            htmlBlocks[language + '_' + origin + '_' + group].push({
                                blockType: x.identifier,
                                language,
                                origin,
                                group,
                                html
                            });
                        }

                        blockNr++;
                    });
                });
            });
        });

        return htmlBlocks;
    }

    /**
     * Setup HTMLs
     * This merges the blocks into one HTML
     */
    static async setupHTMLs(task, languages, origins, groups, settingsModel, base, htmlBlocks, conditionsModel) {
        const htmls = [];

        // Languages loop
        languages.forEach((language) => {
            origins.forEach((origin) => {
                groups.forEach((group) => {
                    // Get variables
                    let campaign = store.getState().editor.data; // eslint-disable-line
                    let editor = DataHelpers.clone(store.getState().editor); // eslint-disable-line
                    editor.origin = origin; // eslint-disable-line
                    let market = editor.market; // eslint-disable-line
                    let customerHelper = new CustomerHelperLoader.helper(); // eslint-disable-line
                    let testVariant = group ? group : ''; // eslint-disable-line

                    // Get subject
                    function getSubject() {
                        // eslint-disable-line
                        return getElementCopy('subject');
                    }

                    // Get snippet
                    function getSnippet() {
                        // eslint-disable-line
                        return getElementCopy('snippet');
                    }

                    // Get element with copy
                    function getElementCopy(path) {
                        // eslint-disable-line
                        let element = DataHelpers.getValue(campaign, settingsModel + '.' + path + '.' + language + '.value');

                        if (!element) {
                            element = DataHelpers.getValue(campaign, settingsModel + '.' + path + '.value');
                        }

                        // Get subject from subset
                        const subsetActive = DataHelpers.getValue(campaign, settingsModel + '.generalSubset');
                        if (subsetActive) {
                            const subsetElement = DataHelpers.getValue(
                                campaign,
                                settingsModel + '.subsets.' + subsetActive + '.' + path + '.' + language + '.value'
                            );
                            // If there is no element in subset, use the original one.
                            element = subsetElement ? subsetElement : element;
                        }

                        if (!element) {
                            return '';
                        }

                        return element;
                    }

                    // Get element
                    function getElement(path) {
                        // eslint-disable-line
                        const element = DataHelpers.getValue(campaign, settingsModel + '.' + path + '.' + language + '.value');
                        return element;
                    }

                    // Get general copy
                    function getGeneralCopy(path) {
                        // eslint-disable-line
                        const value = DataHelpers.getValue(campaign, path + '.' + language + '.value');
                        return value;
                    }

                    // Merge HTMLs
                    let blocksHTML = '';
                    htmlBlocks[language + '_' + origin + '_' + group].forEach((block) => {
                        blocksHTML = blocksHTML + block.html;
                    });

                    // Wrap base
                    let main = blocksHTML; // eslint-disable-line
                    let outputHTML = base.html;
                    try {
                        outputHTML = eval('`' + base.html + '`'); // eslint-disable-line
                    } catch (e) {
                        console.log('Error rendering email block ', e);
                    }

                    // Fix replace for \0 items
                    outputHTML = outputHTML.replace('###0###', '\\0'); // eslint-disable-line
                    outputHTML = outputHTML.replace('###0###', '\\0'); // eslint-disable-line
                    outputHTML = outputHTML.replace('###0###', '\\0'); // eslint-disable-line
                    outputHTML = outputHTML.replace('###0###', '\\0'); // eslint-disable-line
                    outputHTML = outputHTML.replace('###0###', '\\0'); // eslint-disable-line

                    if (customerHelper.publishEmailHTMLProcessingFull) {
                        outputHTML = customerHelper.publishEmailHTMLProcessingFull(outputHTML, task);
                    }

                    htmls.push({
                        language,
                        origin,
                        outputHTML,
                        group,
                        subject: getSubject()
                    });
                });
            });
        });

        return htmls;
    }

    /**
     * Get Tasklists from a list of HTMLs
     * @param {*} mailHTMLs
     */
    static async getTaskList(task, mailHTMLs, campaign, productNamePrefix) {
        const newTasks = [];
        const files = {};
        const multiEmailTitle = task.title ? ` - ${task.title}` : '';

        // Save / export files
        mailHTMLs.forEach((item) => {
            const { origin = '', language = '', outputHTML, group = '', subject = 'Subject' } = item;

            // Set filename
            let filename;
            if (!item.filenameBase) {
                item.filenameBase = (language ? '_' + language : '') + (origin !== 'GEN' ? '_' + origin : '') + (group !== 'all' ? '_group' + group : '');
                filename = campaign.settings.title.replace(/ /g, '-');
                filename = filename.replace(/--/g, '-');
                filename = filename + item.filenameBase;
            } else {
                filename = item.filenameBase;
            }

            // Allow full processing of the email upon export / creation
            const customerHelper = new CustomerHelperLoader.helper();

            // Create file storage task
            const newTask = {
                service: 'files',
                type: 'storeFile',
                extension: 'html',
                source: outputHTML,
                filename: filename,
                resourceId: 'email_' + item.filenameBase
            };
            newTasks.push(newTask);
            files[newTask.filename + '.html'] = '{{' + newTask.resourceId + '}}';

            // Publish as product
            newTasks.push({
                service: 'publish',
                type: 'product',
                name: productNamePrefix + ' HTML - ' + item.filenameBase.replace('_', ' '),
                product: '{{email_' + item.filenameBase + '}}'
            });

            // Publish to acoustic
            if (task.acoustic) {
                let mailingName =
                    EditorData.get('title') +
                    multiEmailTitle +
                    ' - ' +
                    (origin !== 'GEN' ? origin + ' - ' : '') +
                    (task.acoustic.namePrefix ? task.acoustic.namePrefix + ' - ' : '') +
                    language +
                    ' (' +
                    moment().format('YYYY-MM-DD-HHmm') +
                    ')';

                if (task.mailingName) {
                    const mailingNameNew = EditorData.getDynamicValue(task.mailingName);
                    if (mailingNameNew) {
                        mailingName = mailingNameNew + ' (' + moment().format('YYYY-MM-DD-HHmm') + ')';
                    }
                }

                newTasks.push({
                    service: 'acoustic',
                    type: 'uploadEmail',
                    mailingName: mailingName,
                    subject: subject,
                    listId: task.acoustic.listId,
                    folderPath: task.acoustic.folderPath,
                    fromName: task.acoustic.fromName,
                    fromAddress: task.acoustic.fromAddress,
                    replyTo: task.acoustic.replyTo,
                    body: outputHTML
                });
            }

            // Publish to selligent
            if (task.selligent) {
                // Get the mailingname as a default
                let mailingName =
                    EditorData.get('title') +
                    multiEmailTitle +
                    ' - ' +
                    (origin !== 'GEN' ? origin + ' - ' : '') +
                    (task.selligent.namePrefix ? task.selligent.namePrefix + ' - ' : '') +
                    language +
                    ' (' +
                    moment().format('YYYY-MM-DD-HHmm') +
                    ')';

                // We have a mailingname in the task
                if (task.mailingName) {
                    const mailingNameNew = EditorData.getDynamicValue(task.mailingName);
                    if (mailingNameNew) {
                        mailingName = mailingNameNew + ' (' + moment().format('YYYY-MM-DD-HHmm') + ')';
                    }
                }

                if (mailingName.length > 100) {
                    mailingName = mailingName.substring(0, 95) + '...';
                }

                newTasks.push({
                    service: 'selligent',
                    type: 'uploadEmail',
                    mailingName: mailingName,
                    subject: task.selligent.subject ? task.selligent.subject : subject,
                    folderId: task.selligent.folderId,
                    listId: task.selligent.listId,
                    segmentId: task.selligent.segmentId,
                    emailDomainId: task.selligent.emailDomainId,
                    queueId: task.selligent.queueId,
                    from_addr: task.selligent.from_addr,
                    from_name: task.selligent.from_name,
                    reply_addr: task.selligent.reply_addr,
                    reply_name: task.selligent.reply_name,
                    to_addr: task.selligent.to_addr,
                    to_name: task.selligent.to_name,
                    body: outputHTML
                });
            }

            // Publish to salesforce
            if (task.salesforce) {
                let mailingName =
                    EditorData.get('title') +
                    multiEmailTitle +
                    ' - ' +
                    (origin !== 'GEN' ? origin + ' - ' : '') +
                    (task.salesforce.namePrefix ? task.salesforce.namePrefix + ' - ' : '') +
                    language +
                    (group !== 'all' ? '_group' + group : '') +
                    ' (' +
                    moment().format('YYYY-MM-DD-HHmm') +
                    ')';

                // We have a mailingname in the convertor
                if (item.salesforce && item.salesforce.mailingName) {
                    mailingName = item.salesforce.mailingName;
                }
                // We have a mailing name in the task
                else if (task.mailingName) {
                    const mailingNameNew = EditorData.getDynamicValue(task.mailingName);
                    if (mailingNameNew) {
                        mailingName = mailingNameNew + ' (' + moment().format('YYYY-MM-DD-HHmm') + ')';
                    }
                }

                // Get custom data object from salesforce
                let customData;
                if (item.salesforce && item.salesforce.data) {
                    customData = item.salesforce.data;
                } else if (task.salesforce && task.salesforce.data) {
                    customData = item.salesforce.data;
                }

                newTasks.push({
                    service: 'salesforce',
                    type: 'uploadEmail',
                    mailingName: mailingName,
                    subject: task.salesforce.subject ? task.salesforce.subject : subject,
                    preheader: task.salesforce.preheader ? task.salesforce.preheader : '',
                    categoryId: task.salesforce.categoryId,
                    body: outputHTML,
                    market: task.market,
                    businessUnitId: task.salesforce.businessUnitId,
                    data: customData
                });
            }

            // Publish to responsys
            if (task.responsys) {
                let mailingName =
                    EditorData.get('title') +
                    multiEmailTitle +
                    ' ' +
                    (origin !== 'GEN' ? origin + ' ' : '') +
                    language +
                    (group !== 'all' ? '_group' + group : '') +
                    ' ' +
                    moment().format('YYYY-MM-DD-HHmm');

                // We have a mailingname in the convertor
                if (item.responsys && item.responsys.mailingName) {
                    mailingName = item.responsys.mailingName;
                }
                // We have a mailing name in the task
                else if (task.mailingName) {
                    const mailingNameNew = EditorData.getDynamicValue(task.mailingName);
                    if (mailingNameNew) {
                        mailingName = mailingNameNew + '_' + moment().format('YYYY-MM-DD-HHmm');
                    }
                }

                mailingName = mailingName.replace(/ /g, '-');
                mailingName = mailingName.replaceAll("'", '');

                if (mailingName.length > 80) {
                    mailingName = mailingName.substring(0, 80);
                }
                newTasks.push({
                    service: 'responsys',
                    type: 'uploadEmail',
                    documentPath: task.responsys.documentPath + '/' + mailingName + '.html',
                    content: outputHTML
                });
            }

            // Publish to adobe
            if (task.adobe) {
                let mailingName =
                    EditorData.get('title') +
                    multiEmailTitle +
                    ' ' +
                    (origin !== 'GEN' ? origin + ' ' : '') +
                    language +
                    (group !== 'all' ? '_group' + group : '') +
                    ' ' +
                    moment().format('YYYY-MM-DD-HHmm');

                // We have a mailingname in the convertor
                if (item.adobe && item.adobe.mailingName) {
                    mailingName = item.adobe.mailingName;
                }
                // We have a mailing name in the task
                else if (task.mailingName) {
                    const mailingNameNew = EditorData.getDynamicValue(task.mailingName);
                    if (mailingNameNew) {
                        mailingName = mailingNameNew + '_' + moment().format('YYYY-MM-DD-HHmm');
                    }
                }

                mailingName = mailingName.replace(/ /g, '-');
                mailingName = mailingName.replaceAll("'", '');

                newTasks.push({
                    service: 'adobe',
                    type: 'uploadEmail',
                    name: mailingName,
                    description: 'Email created by Cape',
                    template: outputHTML
                });
            }
        });

        newTasks.push({
            service: 'files',
            type: 'archive',
            files: files,
            resourceId: 'archive'
        });

        // Create zip
        newTasks.push({
            service: 'publish',
            type: 'product',
            name: productNamePrefix + ' zip',
            product: '{{archive}}'
        });

        return newTasks;
    }
}
