/* eslint-disable import/order */

// Helpers
import HTML5 from './helpers/html5';
import HTML5GIF from './helpers/html5gif';
import Loop from './helpers/loop';
import Email from './helpers/email';
import MultiEmail from './helpers/multiEmail';
import ExportToExcel from './helpers/exporttoexcel';
import CreativeBuilder from './helpers/creative-builder';
import HTMLExport from './helpers/htmlexport';
import SDF from './helpers/sdf';
import ExportToFile from './helpers/exporttofile';
import DataHelpers from 'components/data/DataHelpers';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import SnackbarUtils from '../../ui-base/SnackbarUtils';
import EditorData from 'components/editor-data/EditorData';

// #if process.env.customer === 'switch'
// import SwitchGym from './helpers/switchgym';
// #endif

// #if process.env.customer === 'philips'
// import PhilipsDCM from './helpers/philips-dcm';
// import PhilipsDV360 from './helpers/philips-dv360';
// #endif

/**
 * PublishProfileParser
 * This class is used to convert generic local tasks, to full sets of tasks for the publish engine.
 * E.g. from one DCM campaign a list of all zips, create tasks etc.
 */
class PublishProfileParser {
    /**
     * Parse a publish profile
     * @param {*} publishProfile The full profile
     */
    static async parse(publishProfile) {
        try {
            // Clone the tasks
            let tasks = DataHelpers.clone(publishProfile.tasks);

            // Check if there is a list of partial publishing id's
            const partialPublish = publishProfile.partialPublish;

            // If there is a partialPublish value, add that to some processing functions tasks
            if (partialPublish && partialPublish.length > 0) {
                tasks = tasks.map((task) => {
                    if (['exportToFile', 'creativeBuilder', 'multiEmail'].includes(task.processingFunction)) {
                        return {
                            ...task,
                            partialPublish
                        };
                    }

                    return task;
                });
            }
            // Use data function
            tasks = await PublishProfileParser.handleDataFunctions(tasks);

            // Parse deep data if needed.
            EditorData.parseDataDeep(tasks);
            return tasks;
        } catch (e) {
            if (e && e.message) {
                console.log('Error publishing: ', e);
                SnackbarUtils.error(e.message);
            } else {
                console.log('Error publishing: ', e);
                SnackbarUtils.error(e);
            }
            return false;
        }
    }

    /**
     * Handle data function
     * @param {*} profile
     */
    static async handleDataFunctions(profile) {
        let found = true;

        // Loop through all tasks and look for known functions
        while (found) {
            found = false;
            for (const i in profile) {
                const task = profile[i];

                // Check condition
                if (task.condition && !EditorData.validateCondition(task)) {
                    profile[i] = false;
                    continue;
                }
                // Create display formats HTML5
                else if (task.customerFunction) {
                    const customerHelper = new CustomerHelperLoader.helper();
                    let newItems = [];
                    if (task.async) {
                        if (customerHelper.publishProfileParserAsync) {
                            newItems = await customerHelper.publishProfileParserAsync(task);
                        } else {
                            newItems = await customerHelper.publishProfileParser(task);
                        }
                    } else {
                        newItems = customerHelper.publishProfileParser(task);
                    }

                    // Replace old item by several new ones
                    profile.splice(i, 1);
                    profile.splice(i, 0, ...newItems);
                    found = true;
                    break;
                }

                // Processing function
                else if (task.processingFunction) {
                    let newItems = [];

                    if (task.processingFunction === 'displayFormatsHTML5') {
                        newItems = await HTML5.handle(DataHelpers.clone(task));
                    } else if (task.processingFunction === 'displayFormatsGif') {
                        newItems = await HTML5GIF.handle(DataHelpers.clone(task));
                    } else if (task.processingFunction === 'loopTasks') {
                        newItems = await Loop.handle(DataHelpers.clone(task));
                    } else if (task.processingFunction === 'email') {
                        newItems = await Email.handle(DataHelpers.clone(task));
                    } else if (task.processingFunction === 'multiEmail') {
                        newItems = await MultiEmail.handle(DataHelpers.clone(task));
                    } else if (task.processingFunction === 'exportToExcel') {
                        newItems = await ExportToExcel.handle(DataHelpers.clone(task));
                    } else if (task.processingFunction === 'creativeBuilder') {
                        newItems = await CreativeBuilder.handle(DataHelpers.clone(task));
                    } else if (task.processingFunction === 'htmlExport') {
                        newItems = await HTMLExport.handle(DataHelpers.clone(task));
                    } else if (task.processingFunction === 'sdf') {
                        newItems = await SDF.handle(DataHelpers.clone(task));
                    } else if (task.processingFunction === 'exportToFile') {
                        newItems = await ExportToFile.handle(DataHelpers.clone(task));
                    }

                    // #if process.env.customer === 'switch'
//                     else if (task.processingFunction === 'switchTheGymVideo') {
//                         newItems = await SwitchGym.handle(DataHelpers.clone(task));
//                     }
                    // #endif

                    // #if process.env.customer === 'philips'
//                     else if (task.processingFunction === 'philipsDcm') {
//                         newItems = await PhilipsDCM.handle(DataHelpers.clone(task));
//                     } else if (task.processingFunction === 'philipsDV360') {
//                         newItems = await PhilipsDV360.handle(DataHelpers.clone(task));
//                     }
                    // #endif

                    // Replace old item by several new ones
                    profile.splice(i, 1);
                    profile.splice(i, 0, ...newItems);
                    found = true;
                    break;
                }
            }
        }
        profile = profile.filter((n) => n);
        return profile;
    }
}

export default PublishProfileParser;
