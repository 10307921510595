import EditorData from 'components/editor-data/EditorData';
import Email from './email';

/**
 * Multi Email task handler
 * Creates multiple email htmls from the blocks in case of partial publishing
 */
export default class MultiEmail {
    static async handle(task) {
        if (!Array.isArray(task.partialPublish)) {
            return [];
        }
        const promises = [];
        task.partialPublish.forEach((part) => {
            const partialTask = {
                ...task,
                model: `${task.dataModel}.${part}.setup`,
                dataModel: `${task.dataModel}.${part}.blocks`,
                settingsModel: `${task.dataModel}.${part}.settings`,
                conditionsModel: `${task.dataModel}.${part}.conditions`,
                abTesting: `${task.dataModel}.${part}.settings.abTesting.test`
            };
            const setup = EditorData.getValueFromModel(task.model);
            const found = setup.find((item) => item.uuid === part);
            if (found && found.name) partialTask.title = found.name;

            const email = Email.handle(partialTask);
            promises.push(email);
        });
        const result = await Promise.all(promises);
        const newTasks = [].concat.apply([], result);
        return newTasks;
    }
}
