import { merge } from 'lodash';
import { chunk } from 'lodash';
import EditorData from 'components/editor-data/EditorData';
import Resources from 'components/data/Resources';
import DataHelpers from 'components/data/DataHelpers';
import Loop from './loop';
import Translate from './translate';

/**
 * HTML5 export class
 * This function exports ads for various HTML5 formats
 * You can choose between image and HTML.
 */
export default class HTML5 {
    // Remove galleries of images
    static removeGallery(data) {
        if (typeof data === 'object' && data !== null) {
            for (const i in data) {
                if (data[i] && typeof data[i] === 'object' && (data[i] !== null) & data[i].assetGalleryInput) {
                    const newData = { url: data[i].url };
                    if (data[i].transforms) {
                        newData.transforms = data[i].transforms;
                    }
                    data[i] = newData;
                } else if (i == 'gallery' || i == 'originalImage') {
                    delete data[i];
                } else {
                    this.removeGallery(data[i]);
                }
            }
        }
    }

    // Handle the task
    static async handle(task) {
        let allFormats = Resources.get('displayFormats');
        if (!allFormats) {
            allFormats = {};
        }

        const data = EditorData.getValueFromModel(task.sourceDataModel);
        const market = EditorData.get('market');
        const newTasks = [];
        const outputHTML = {};
        const outputImages = {};
        const outputCombinations = Loop.getCombinations(task.multiLanguage, task.loopItems);
        const allFiles = [];

        if (task.formatsModel) {
            task.formats = EditorData.getValueFromModel(task.formatsModel);
        }

        // Select all output combinations
        outputCombinations.forEach((variables) => {
            let filenameString = '';

            Object.keys(variables).forEach((key) => {
                const item = variables[key];
                const filenamePart = item.toLowerCase().replace(/[^a-z0-9]/gi, '_');
                filenameString = filenameString + '-' + filenamePart;
            });
            filenameString = filenameString.substr(1);

            // Loop through tasks
            task.formats.forEach((format) => {
                // Get width and height
                let width;
                let height;
                if (allFormats[format]) {
                    width = allFormats[format].width;
                    height = allFormats[format].height;
                } else if (format.substr(0, 6) === 'format') {
                    const formatKeyParts = format.split('_');
                    const sizes = formatKeyParts[1].split('x');
                    width = parseInt(sizes[0]);
                    height = parseInt(sizes[1]);
                }

                // Create new data
                let formatData = DataHelpers.clone(data);
                this.removeGallery(formatData);

                // Find the right data format in the overwrites en merge those
                if (formatData && formatData.overwrites && formatData.overwrites[format]) {
                    formatData = merge(formatData, formatData.overwrites[format]);
                }
                if (formatData && formatData.overwrites) {
                    delete formatData.overwrites;
                }

                if (variables.language) {
                    Translate.handle(variables.language, formatData);
                }

                // Merge other data that we want to include at publish time
                if (task.mergeData) {
                    // Get the data to be merged from the task
                    const mergeData = DataHelpers.clone(task.mergeData);
                    // Merge the objects together
                    formatData = merge(formatData, mergeData);
                    // In case there are variables
                    EditorData.parseDataDeep(formatData, variables);
                }

                const extension = task.extension ? task.extension : 'jpg';
                const formatString = format + '-' + width + 'x' + height;

                // Build images
                if (task.type === 'images') {
                    newTasks.push({
                        service: 'creatives',
                        type: 'HTMLToImage',
                        url: task.imageTemplate,
                        width: width,
                        height: height,
                        extension: extension,
                        name: format,
                        quality: task.quality ? task.quality : 100,
                        includeRetinaSize: !task.noRetinaSize,
                        hashStringifiedJSON: {
                            ...formatData,
                            format: format,
                            frameNr: task.stillFrameNr ? task.stillFrameNr : 0,
                            editmode: 0,
                            market: market
                        },
                        resourceId: task.displayKey + '-' + formatString + '-' + filenameString + '-image',
                        resources: {
                            url_x2: task.displayKey + '-' + formatString + '-' + filenameString + '-image-x2'
                        }
                    });

                    // Add the files to the all files array of the function
                    allFiles.push({
                        name: task.displayKey + '-' + formatString + '.jpg',
                        file: '{{' + task.displayKey + '-' + formatString + '-' + filenameString + '-image}}'
                    });
                    outputImages[task.displayKey + '-' + formatString + '-' + filenameString + '.' + extension] =
                        '{{' + task.displayKey + '-' + formatString + '-' + filenameString + '-image}}';

                    if (!task.disableX2) {
                        allFiles.push({
                            name: task.displayKey + '-' + formatString + '@2x.jpg',
                            file: '{{' + task.displayKey + '-' + formatString + '-' + filenameString + '-image-x2}}'
                        });
                        outputImages[task.displayKey + '-' + formatString + '-' + filenameString + '-x2.' + extension] =
                            '{{' + task.displayKey + '-' + formatString + '-' + filenameString + '-image-x2}}';
                    }
                }
                // Build HTML 5 zips
                else {
                    newTasks.push({
                        service: 'displayads',
                        type: 'html5',
                        template: task.template,
                        images: {},
                        data: {
                            ...formatData,
                            format: format,
                            frameNr: 0,
                            editmode: 0,
                            frameDuration: 4000,
                            market: market
                        },
                        backupImageGetParameters: {
                            frameNr: task.stillFrameNr
                        },
                        width: width,
                        height: height,
                        htmlResourceId: task.displayKey + '-' + formatString + '-' + filenameString + '-html',
                        imageResourceId: task.displayKey + '-' + formatString + '-' + filenameString + '-image',
                        imageResourceId_x2: task.displayKey + '-' + formatString + '-' + filenameString + '-image-x2'
                    });

                    // Add the files to the all files array of the function
                    allFiles.push({
                        name: task.displayKey + '-' + formatString + '.zip',
                        file: '{{' + task.displayKey + '-' + formatString + '-' + filenameString + '-html}}'
                    });
                    allFiles.push({
                        name: task.displayKey + '-' + formatString + '.jpg',
                        file: '{{' + task.displayKey + '-' + formatString + '-' + filenameString + '-image}}'
                    });

                    // Create output zip
                    outputHTML[task.displayKey + '-' + formatString + '-' + filenameString + '.zip'] =
                        '{{' + task.displayKey + '-' + formatString + '-' + filenameString + '-html}}';
                    outputImages[task.displayKey + '-' + formatString + '-' + filenameString + '.' + extension] =
                        '{{' + task.displayKey + '-' + formatString + '-' + filenameString + '-image}}';

                    if (!task.disableX2) {
                        allFiles.push({
                            name: task.displayKey + '-' + formatString + '@2x.jpg',
                            file: '{{' + task.displayKey + '-' + formatString + '-' + filenameString + '-image-x2}}'
                        });
                        outputImages[task.displayKey + '-' + formatString + '-' + filenameString + '-x2.' + extension] =
                            '{{' + task.displayKey + '-' + formatString + '-' + filenameString + '-image-x2}}';
                    }
                }
            });
        });

        // Merge tasks (creation of zip, packing it and publishing it)
        let files;
        if (task.type === 'images') {
            files = outputImages;
        } else {
            files = {
                html: outputHTML,
                images: outputImages
            };
        }

        // Setup task list
        let taskResult = [];
        if (!task.disableParallel) {
            const chunks = chunk(newTasks, 6);
            for (const i in chunks) {
                taskResult = [
                    ...taskResult,
                    {
                        service: 'publish',
                        type: 'parallel',
                        tasks: chunks[i]
                    }
                ];
            }
        } else {
            taskResult = newTasks;
        }

        taskResult = [
            ...taskResult,
            {
                service: 'files',
                type: 'archive',
                files: files,
                resourceId: 'archive'
            },
            {
                service: 'publish',
                type: 'product',
                name: 'Download zip',
                product: '{{archive}}'
            }
        ];

        // Adds links file
        // This option includes a txt file with all urls.
        if (task.addLinksFile) {
            let outputText = '';
            allFiles.forEach((item) => {
                outputText = outputText + item.name + ': ' + item.file + '\n';
            });

            taskResult.push({
                service: 'files',
                type: 'storeFile',
                extension: 'txt',
                source: outputText,
                filename: 'all-links',
                resourceId: 'all-links'
            });
            taskResult.push({
                service: 'publish',
                type: 'product',
                name: 'Download all links',
                product: '{{all-links}}'
            });
        }

        return taskResult;
    }
}
