import EditorData from 'components/editor-data/EditorData';
import DataHelpers from 'components/data/DataHelpers';

export default class Loop {

    static async handle(taskOriginal) {

        let task = DataHelpers.clone(taskOriginal);
        let newTasks = [];

        if (!task.subsets) {
            task.subsets = [
                { 'task': task.task, 'loopItems': task.loopItems }
            ];
        }

        let products = [];

        // Loop through subset
        task.subsets.forEach((subset) => {
            let loopTasks = [];
            let outputCombinations = Loop.getCombinations(task.multiLanguage, subset.loopItems);

            // Create a new task for all combinations
            outputCombinations.forEach((variables) => {

                let newTask = DataHelpers.clone(subset.task);
                EditorData.parseDataDeep(newTask, variables);

                if (!subset.task.active || newTask.active) {
                    loopTasks.push(newTask);
                    if (newTask.resourceId) {
                        products.push(newTask.resourceId);
                    }
                }
            });

            // Create task for parallel
            if (task.parallel) {
                newTasks.push({
                    'service': 'publish',
                    'type': 'parallel',
                    'tasks': loopTasks
                });
            }
            else {
                newTasks = loopTasks;
            }

        });


        // Create task for product output
        if (task.zipProducts) {
            // Create files
            let files = {};

            products.forEach((file) => {
                if (task.subsets[0].task.service == 'affectivity') {
                    files[file + '.mp4'] = '{{' + file + '}}';
                }
            });

            newTasks.push({
                'service': 'files',
                'type': 'archive',
                'files': files,
                'resourceId': 'archive'
            });

            // Create zip
            newTasks.push({
                'service': 'publish',
                'type': 'product',
                'name': 'Download zip',
                'product': '{{archive}}'
            });
        }
        return newTasks;

    }

    /**
     * Get all the combinations of language and loopitems
     * @param {*} multiLanguage 
     * @param {*} loopItems 
     */
    static getCombinations(multiLanguage, loopItems) {

        // Loop through languages
        let languages = [{ 'language': '' }];
        if (multiLanguage) {
            languages = [];
            Object.keys(EditorData.getValueFromModel('settings.languages')).forEach((languageCode) => {
                languages.push({ 'language': languageCode });
            });
        }

        // Create all combinations
        let outputCombinations = languages;

        /** 
         * Loopitems have this format:
         * [
            {
              "variable": "location",
              "dataset": "data.locations",
              "subKey":"location"
            }
          ]
         * 
         *  */
        if (loopItems && loopItems.map) {
            loopItems.forEach((loopItem) => {
                let outputCombinationsNew = [];
                outputCombinations.forEach((existingItem) => {

                    let loopItemData = EditorData.getDynamicValue('[[[campaign]]].' + loopItem.dataset, existingItem);

                    if (loopItemData && loopItemData.map) {
                        loopItemData.forEach((listItem) => {
                            let itemValue = listItem;
                            if (loopItem.subKey) {
                                itemValue = itemValue[loopItem.subKey];
                            }

                            // Output combinations
                            outputCombinationsNew.push({
                                ...existingItem,
                                [loopItem.variable]: itemValue
                            })
                        });
                    }
                });
                outputCombinations = outputCombinationsNew;
            });
        }


        /**
         * Loopitems have this format:
         *"loopItems":{
            "dish":"[[[campaign]]].video.dishes.[[language]]",
            "location":"[[[campaign]]].video.location.[[language]]",
            "format":"{{{['main1','main2']}}}"
          }
         */
        if (loopItems && !loopItems.map) {
            Object.keys(loopItems).forEach((loopItemKey) => {
                let outputCombinationsNew = [];
                outputCombinations.forEach((existingItem) => {

                    let dataSource = loopItems[loopItemKey];
                    let loopItemData;

                    if (Array.isArray(dataSource)) {
                        loopItemData = dataSource;
                    }
                    else {
                        loopItemData = EditorData.getDynamicValue(dataSource);
                    }

                    if (loopItemData && loopItemData.map) {
                        loopItemData.forEach((listItem) => {
                            let itemValue = listItem;
                            // Output combinations
                            outputCombinationsNew.push({
                                ...existingItem,
                                [loopItemKey]: itemValue
                            })
                        });
                    }
                });

                outputCombinations = outputCombinationsNew;
            });
        }

        if (outputCombinations.length == 0) {
            throw new Error('There is no dataset defined')
        }

        return outputCombinations;

    }

}
