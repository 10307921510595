import EditorData from 'components/editor-data/EditorData';
import Templates from 'components/data/Templates';
import store from '../../../../store';

/**
 * HTML task handler
 * Creates email htmls from the blocks
 */
export default class HTMLExport {
    static async handle(task) {
        const templates = await Templates.asyncGetTemplatesList([{ type: 'landingpageBlock', fullData: true }]);
        const template = templates[task.identifier];

        // Create HTML
        const newTasks = [];
        const files = {};

        let languages = [''];
        if (task.multiLanguage) {
            languages = Object.keys(EditorData.getValueFromModel('settings.languages'));
        }

        // Languages loop
        languages.forEach((language) => {
            let blocksHTML = ''; // eslint-disable-line

            // Get variables
            const campaign = store.getState().editor.data;
            let editorData = EditorData; // eslint-disable-line

            // Wrap base
            let outputHTML = template.html;
            try {
                outputHTML = eval('`' + template.html + '`'); // eslint-disable-line
            } catch (e) {
                console.log('Error rendering html block ', e);
            }

            // Add task
            let filename = campaign.settings.title.replace(/ /g, '-');
            filename = filename.replace(/--/g, '-');

            const extension = task.extension ? task.extension : 'html';
            const newTask = {
                service: 'files',
                type: 'storeFile',
                extension: extension,
                source: outputHTML,
                filename: filename,
                resourceId: 'file_' + language
            };
            newTasks.push(newTask);
            files[newTask.filename + '.' + extension] = '{{' + newTask.resourceId + '}}';

            // Create HTML
            newTasks.push({
                service: 'publish',
                type: 'product',
                name: 'Download ' + extension + ' - ' + language,
                product: '{{file_' + language + '}}'
            });
        });

        newTasks.push({
            service: 'files',
            type: 'archive',
            files: files,
            resourceId: 'archive'
        });

        // Create zip
        newTasks.push({
            service: 'publish',
            type: 'product',
            name: 'Download zip',
            product: '{{archive}}'
        });

        return newTasks;
    }
}
