import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import PublishHelpers from 'components/data/PublishHelpers';
import EditorData from 'components/editor-data/EditorData';

/**
 * Export to Excel
 * This function loops through the entire campaign setup and converts it into a JSON that is converted to XLSX by the publishengine.
 */
export default class ExportToFile {
    static async handle(task) {
        const customerHelper = new CustomerHelperLoader.helper();
        const editor = EditorData.get();

        const languages = Object.keys(EditorData.getValueFromModel('settings.languages') || {});
        let creatives = Object.keys(EditorData.getValueFromModel(task.dataModel) || {});

        // let originalItemArray;
        if (creatives && task.partialPublish && task.partialPublish.length > 0) {
            // originalItemArray = itemArray;
            creatives = creatives.filter((item) => task.partialPublish.includes(item));
        }

        let content = '';

        // Predefined structure for this customer
        if (task.customerFunctionETF) {
            try {
                content = customerHelper[task.customerFunctionETF](task, editor, languages, creatives);
            } catch (e) {
                console.error(e);
            }
        }

        if (!content) return [];

        // Return new task
        const newTasks = [
            {
                service: 'files',
                type: 'storeFile',
                extension: task.extension,
                source: content,
                filename: 'output-file.' + task.extension,
                resourceId: 'output-file'
            },
            {
                service: 'publish',
                type: 'product',
                name: 'Download file',
                product: '{{output-file}}'
            }
        ];

        return newTasks;
    }
}
